import gsap from "scripts/gsap";

export default function showModal({isOpen, setIsOpen, modalWrapper, css, maxOpacity = 1}){
  return new Promise((resolve, reject) => {
    if (!isOpen){
      setIsOpen(true);
      let tl = gsap.timeline({});
      tl.set(modalWrapper.current, {visibility: "visible"});
      tl.fromTo(modalWrapper.current.querySelector(".ink"), {
        opacity: 0,
      }, {
        opacity: maxOpacity,
        duration: 0.3,
        ease: "sine.in",
      });

      tl.fromTo(modalWrapper.current.querySelectorAll(".blot"), {
        scaleX: 0,
        scaleY: 0,
        rotate: `${(Math.random() * 45) - 22}deg`,
        transformOrigin: "center center",
      }, {
        scaleX: 1,
        scaleY: 1,
        rotate: "0deg",
        duration: 0.3,
        ease: "sine.in",
        stagger: {
          amount: 0.3,
          ease: "sine.in",
        },
      }, "<");
      tl.fromTo(modalWrapper.current.querySelector(`.${css.modal}`), {
        opacity: 0,
      }, {
        opacity: 1,
        ease: "sine.in",
        duration: 0.25,
        onComplete: resolve,
      }, "<50%");
    }
  });
}
