import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import InkbleedSvg from "./inkbleed.svg";
import css from "./index.module.scss";
import showModal from "./showModal";
import hideModal from "./hideModal";
import CancelSvg from "/public/images/icons/cancel.svg";

export default forwardRef(({
  children,
  delay = -1,
  name = "",
  className = "",
  onShow = () => {},
  onHide = () => {},
  isOpenInitial = false,
  fullscreen = false,
  translucent = false,
}, ref) => {
  const modalWrapper = useRef();
  const [isOpen, setIsOpen] = useState(isOpenInitial);
  const [alreadyOpened, setAlreadyOpened] = useState(isOpenInitial);

  let maxOpacity = 1;
  if (translucent){
    maxOpacity = 0.8;
  }

  useImperativeHandle(ref, () => ({
    show() {
      return showModal({css, isOpen, setIsOpen, modalWrapper, maxOpacity});
    },
    hide() {
      return hideModal({css, isOpen, setIsOpen, modalWrapper, maxOpacity});
    },
  }));

  useEffect(() => {
    if (delay >= 0){
      let handle = setTimeout(() => {
        if (!alreadyOpened){
          setAlreadyOpened(true);
          showModal({css, isOpen, setIsOpen, modalWrapper, maxOpacity});
          onShow(name);
        }
      }, delay);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [alreadyOpened, delay, isOpen, name, onShow, maxOpacity]);

  return (
    <div className={`${!isOpenInitial ? "hidden" : ""} ${className} ${css.modalWrapper}`} ref={modalWrapper}>
      <InkbleedSvg />
      <div className={css.modal}>
        <div className={`${css.modalInner} ${fullscreen ? css.fullscreen : ""}`}>
          <button aria-label="Close" className={css.cancel} onClick={() => {
            onHide(name);
            hideModal({css, isOpen, setIsOpen, modalWrapper, maxOpacity});
          }}><CancelSvg/></button>
          {children}
        </div>
      </div>
    </div>
  );
});
