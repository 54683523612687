import Link from "next/link";
import React from "react";
import mpTag from "scripts/tag/mpTag";

import css from "./index.module.scss";

export default function Button({
  ghost = false,
  light = false,
  grayscale = false,
  href = "",
  onClick = () => {},
  onMouseDown = () => {},
  label = "",
  className = "",
  icon = null,
  disabled = false,
  target = "_self",
}) {
  let thisClassName = `${className} ${css.button}`;
  if (ghost){
    thisClassName += ` ${css.ghost}`;
  }
  if (light){
    thisClassName += ` ${css.light}`;
  }
  if (grayscale){
    thisClassName += ` ${css.grayscale}`;
  }

  function handleClick(event){
    mpTag("buttonClick", {label});
    onClick(event);
  }


  if (href){
    return <Link href={href}>
      <a
        className={`${thisClassName} custom`}
        onClick={(event) => {
          if (disabled){
            event.stopPropagation();
            event.preventDefault();
          } else {
            handleClick(event);
          }
        }}
        target={target}
        onMouseDown={onMouseDown}
        disabled={disabled}
      >{icon}{label}</a>
    </Link>;
  }
  return <button
    className={thisClassName}
    onClick={handleClick}
    onMouseDown={onMouseDown}
    disabled={disabled}
  >
    {icon}{label}
  </button>;
}
